.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 33, 35, 0.8);
  z-index: 98;
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  background: var(--dark);
  width: 320px;
  height: 100vh;
  z-index: 99;
  padding: 24px;
}

.titleLog {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--light);
  text-align: center;
}

.form {
  /* position: relative; */
  margin-top: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 16px;
}

.group input {
  /* position: absolute; */
  width: 100%;
  background: #212123;
  border-radius: 8px;
  color: var(--light);
  padding: 12px 24px;
}

.submit {
  font-weight: 500;
  line-height: 20px;
}

.close {
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 9;
  cursor: pointer;
}

.close svg {
  width: 20px;
  height: 20px;
  stroke: var(--dark-sea);
}

.close:hover svg {
  stroke: #fff;
}

.link {
  font-size: 13px;
  color: var(--dark-sea);
  cursor: pointer;
  text-align: center;
}

.link:hover {
  color: var(--light);
}

/* .group input:hover {
  border-color: #adffff;
}

.group input:focus {
  border-color: #18ffff;
}

.form__label {
  position: absolute;
  left: 1rem;
  top: 0.8rem;
  padding: 0 0.5rem;
  color: white;
  cursor: text;
  -webkit-transition: top 200ms ease-in, left 200ms ease-in,
    font-size 200ms ease-in;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  background-color: #060b23;
}

.group input:focus ~ .form__label,
.group input:not(:placeholder-shown).group input:not(:focus) ~ .form__label {
  top: -0.5rem;
  font-size: 0.8rem;
  left: 0.8rem;
} */
