.footerFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 24px;
}

.logo {
  width: 100px;
}

.logo a {
  height: 100%;
}

.rights {
  color: var(--dark-sea);
  font-size: 12px;
  line-height: 15px;
}

.rights a {
  color: var(--violet);
}

.socials {
  display: flex;
  gap: 15px;
}

.socials svg {
  width: 24px;
  height: 24px;
  fill: var(--dark-sea);
}

.socials svg:hover {
  fill: var(--violet);
}
