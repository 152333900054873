.sectionCategories {
  /* display: flex;
  flex-grow: 1; */
  width: 100%;
  padding: 22px;
  overflow-x: auto;
}

.listCategories {
  margin-top: 24px;
  display: flex;
  column-gap: 20px;
}

.itemCategories {
  position: relative;
  flex-grow: 1;
  width: calc(100% / 5);
  /* width: 200px; */
  overflow: hidden;
  text-align: center;
}

.imageCategories {
  /* width: 100%; */
  /* width: 100px; */
  height: 130px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 7%;
}

.titleCategories {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 20px;
}

.wrapperCategories {
  /* position: relative; */
  width: 100%;
  padding: 24px;
  flex-grow: 1;
}

.filtersCategories {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 24px;
}

.filterCategories {
  background: var(--bg);
  border-radius: 8px;
  padding: 12px 24px;
  width: max-content;
  display: flex;
  align-items: center;
  min-width: 225px;
}

.fullCategories {
  width: 100%;
}

.filterCategories input {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.filterCategories span {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--dark-sea);
  text-align: right;
}

.moreCategories {
  margin: 32px auto 0 auto;
  text-align: center;
}

.backCategories {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 24px;
}

@media (max-width: 701px) {
  .sectionCategories {
    display: none;
  }
}
@media (max-width: 870px) {
  .filtersCategories {
    display: flex;
    flex-direction: column;
    /* justify-content: end;
    gap: 20px;
    width: 100%;
    margin-top: 24px; */
  }
}
.slider {
  margin: 0 20px;
  overflow: "hidden";
  padding: 2rem 0;
}
.react-multi-carousel-list {
  padding: 1rem 0 2rem 0;
}

.custom-dot-list-style button {
  border: none;
  background: rgb(255, 68, 68);
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: rgb(255, 68, 68) !important;
}

/* .BrandslogoSec {
  background: #f6f6f6;
}
.BrandslogoSec .react-multiple-carousel__arrow--left {
  left: calc(1% + 1px);
}
.BrandslogoSec .react-multiple-carousel__arrow--right {
  right: calc(0% + 1px);
}
.BrandslogoSec .react-multiple-carousel__arrow {
  background: none !important;
}
.BrandslogoSec .react-multiple-carousel__arrow::before {
  color: rgb(161, 157, 157);
} */
