@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700);
@import url(https://raw.github.com/FortAwesome/Font-Awesome/master/docs/assets/css/font-awesome.min.css);

.headerHead {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  padding: 20px 20px 10px 20px;
  z-index: 50;
  /* background-color: #252627; */
}

.logoheder {
  width: 204px;
  height: auto;
}
.logoImg {
  width: 200px;
  height: 100px;
}
@media (max-width: 540px) {
  .logoImg {
    width: 100px;
    height: 85px;
  }
  .logoheder {
    width: 104px;
    height: auto;
  }
}
.infoheder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.userheder {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
}

.avatarheder {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: var(--dark);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.usernameheder {
  font-size: 10px;
  line-height: 12px;
  color: var(--grey);
}

.formheder {
  display: flex;
  align-items: center;
  background: var(--dark);
  border-radius: 6px;
  height: 27px;
  padding: 7px 10px;
  column-gap: 16px;
  width: 300px;
  position: relative;
}

.iconheder {
  width: 13px;
  height: 13px;
}

.icon svg {
  width: 100%;
  height: 100%;
  fill: var(--dark-sea);
}

.input {
  flex-grow: 1;
}

.input input {
  font-family: Montserrat, Roboto, sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: var(--grey);
  background: none;
  outline: none;
  width: 100%;
}

@media (max-width: 900px) {
  .formheder {
    display: none;
  }
}
.formhederMobil {
  margin: auto;
  /* display: inline-block; */
  position: relative;
  height: 30px;
  /* float: right;
  padding: 0; */
}

.inputMobile input[type="text"] {
  margin: 60px 90px;
  margin-top: 60px;
  /* height: 30px; */
  font-size: 20px;
  /* display: inline-block; */
  font-family: "Lato";
  font-weight: 50;
  border: none;
  outline: none;
  color: #555;
  padding: 20px;
  padding-right: 20px;
  width: 0px;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  z-index: 3;
  transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
  cursor: pointer;
}
.inputMobile input[type="text"]:focus:hover {
  border-bottom: 1px solid #bbb;
}

.inputMobile input[type="text"]:focus {
  width: 180px;
  z-index: 1;
  border-bottom: 1px solid #bbb;
  cursor: text;
}
.inputMobile input[type="submit"] {
  margin: 40px 90px;
  height: 67px;
  width: 63px;
  display: inline-block;
  color: red;
  float: right;
  /* background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAABtlBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADYY/hoAAAAkXRSTlMAAggEC0OFpMGvklsaRLb29dnE0O771m4O7eSDOxYJESxoyfqmGwFs87gwF4qxQuoDBX6EsucyLqxFDbA2OvGMDLPsP0h7+Eb3gsWY5h6H8pua2hWX5SaZ4ikhiDRa3wa00hNM+XUHKunH1CBKYlCoCp2QU+8+GbtOEjUQor7wqTecS33PSZHcRxw94+BNnqAdVfvQcQAAAWFJREFUOMtjYCAeMDIx45FlYWVj5+Dk4ubBoZmXj19AUEhYRFRMXAKLPLO4pJS0jKycvIKikrKKKoa8mrqGppYahK2tI6Ari65AT19T1QCqgMHQSNlYDVVezsTUzNzCEsa1sta3QVVga2fv4OjkDOe7CBijKnB103P38ETwJbyEHFAUePv4+vkjmxog5YuiIDAoOCQUWchbPwxFQXgED5dTJLIC0ygUBdEaMbFxMQh+fEJiEoqC5JTUtPR4BN88IxA1IJIys7KR48WePwctJHPdvFkQvDyR/AI0BQWFokXyULZBsVRJKUZslamklFdUAi2Wq+IqceIPqMRQUe1qmiJcExJYW+dR31CU0tgUg5FizJtrI3xCW0JaCxis2tw82jswU41cp6+vPCOIlSMwcWJcK5702dU9ceLEniY8KnqlgCoikgmp6O6lggo9Qir61AioiMabV/X6oycQyM1qxOR5APoxUUbk4232AAAAAElFTkSuQmCC)
    center no-repeat; */
  /* background: rgb(239, 231, 231); */
  text-indent: -10000px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0.4;
  cursor: pointer;
  transition: opacity 0.4s ease;
}

.inputMobile input[type="submit"]:hover {
  opacity: 0.8;
}

@media (min-width: 899px) {
  .formhederMobile {
    display: none;
  }
}

.account {
  display: flex;
  align-items: center;
  column-gap: 1em;
}

.favourites {
  cursor: pointer;
}

.account svg {
  width: 24px;
  height: 24px;
}

.icon-fav {
  stroke: var(--grey);
  fill: none;
}

.icon-search {
  fill: var(--grey);
}
@media (min-width: 900px) {
  .icon-search {
    display: none;
  }
}

.icon-cart {
  fill: var(--grey);
}

.icon-fav:hover {
  stroke: var(--white);
}
.icon-search:hover {
  fill: var(--white);
}

.icon-cart:hover {
  fill: var(--white);
}

.cartHeder {
  position: relative;
}

.count {
  position: absolute;
  width: 14px;
  height: 14px;
  background: var(--violet);
  right: -2px;
  bottom: -2px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 10px;
  color: var(--light);
  text-align: center;
  line-height: 16px;
}

.boxheder {
  z-index: 9;
  top: 120%;
  position: absolute;
  width: 100%;
  left: 0;
  max-height: 300px;
  overflow-y: auto;
  padding: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  background: var(--dark-sea);
  border-radius: 8px;
}

.boxheder::-webkit-scrollbar {
  display: none;
}

.itemheder {
  font-size: 13px;
  color: var(--grey);
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.itemheder:hover {
  color: var(--light);
}

.imageheder {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  height: 50px;
  width: 50px;
}

.buttonTheme {
  background: var(--violet-dark);
  border-radius: 6px;
  padding: 7px 10px;
  font-weight: 400;
  line-height: 10px;
  cursor: pointer;
}
