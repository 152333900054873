.productsPR {
  width: 100%;
  padding: 25px 22px;
}

.listPR {
  margin-top: 24px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.productPR {
  background: var(--bg);
  border-radius: 8px;
  overflow: hidden;
  width: calc(100% / 5 - 20px);
}

.productPR:nth-child(5n) {
  width: calc(100% / 5);
}

.imagePR {
  width: 100%;
  height: 410px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wrapperPR {
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 410px);
}

.titlePR {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}

.catPR {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--grey);
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 28px;
}

.infoPR {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pricesPR {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.pricePR {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--violet-dark);
}

.oldPricePR {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: line-through;
  color: var(--dark-sea);
}

.purchasesPR {
  color: var(--dark-sea);
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

.buttonPR {
  margin-top: 24px;
  text-align: center;
}
@media (max-width: 700px) {
  .listPR {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    /* gap: 20px; */
    /* flex-wrap: wrap; */
  }
  .productPR {
    background: var(--bg);
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
  }
  .productPR:nth-child(5n) {
    width: 100%;
  }
}
