:root {
  /* --bg: #212123;
  --dark: #191919;
  --dark-sea: #576067;
  --grey: #b8b8b8; 
  --white: #fff;
   --violet: #8b57c6;
  --violet-dark: #6c3eb8;
  --light: #f6f6f7;
  --sea: #616e74; */
}

html[data-theme="dark"] {
  --white: #fff;
  --bg: #212123;
  --dark: #191919;
  --dark-sea: #576067;
  --grey: #b8b8b8;

  --violet: #8b57c6;
  --violet-dark: #6c3eb8;
  --light: #f6f6f7;
  --sea: #616e74;
}

html[data-theme="light"] {
  --white: #fff;
  --bg: #616e74;
  --dark: #aeaeb3;
  --dark-sea: #212123;
  --grey: #545252;

  --violet: #6c3eb8;
  --violet-dark: #5c14af;
  --light: #191919;
  --sea: #576067;
}
