.sidebar {
  width: 304px;
  padding: 24px;
  height: 423px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1229px) {
  .sidebar {
    display: none;
  }
}
@media (max-width: 700px) {
  .sidebar {
    position: relative;
    flex-grow: 1;
    width: auto;
    padding: 24px;
    height: 300px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    /* overflow-y: scroll; */
  }
}

.title {
  display: flex;
  justify-content: center;
  font-weight: 500;
  line-height: 20px;
  font-size: 30px;
  color: var(--dark-sea);
}

.menu {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  overflow-y: hidden;
  overflow-y: auto;
}
.menuScr {
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  overflow-y: scroll;
}

.link {
  color: var(--dark-sea);
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
}

.link:hover {
  color: var(--white);
}

.link.active {
  color: var(--violet);
}

.footer {
  margin-top: auto;
  display: flex;
  /* flex-direction: column;
  justify-content: end; */
  /* row-gap: 10px; */
  justify-content: space-around;
}
