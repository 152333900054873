.page_404 {
  position: relative;
  flex-grow: 1;
  padding: 20px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

/* @import "../../images/dribbble_1.gif"; */
.four_zero_four_bg {
  background-image: url("../../images/dribbble_1.gif");
  height: 320px;
  background-position: center;
}

.four_zero_four_bg h1 {
  text-align: center;
  font-size: 50px;
  color: #920fb2;
}

.four_zero_four_bg h3 {
  margin: 10px 0;
  text-align: center;
  font-size: 50px;
  color: #920fb2;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #920fb2;
  margin: 10px 0;
  display: inline-block;
}
.contant_box_404 {
  text-align: center;
  margin-top: -20px;
  color: #920fb2;
}
