.profileProfile {
  flex-grow: 1;
  padding: 24px;
}

.formProfile {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.groupProfile {
  width: 100%;
  background: #212123;
  border-radius: 8px;
  color: var(--light);
  padding: 12px 24px;
}

.group input {
  width: 100%;
}
